<template>
    <div>
      <v-row>
        <v-col class="col4">
          <h2>Contacts for this customer</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col12">
          <v-simple-table
            fixed-header
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Tel</th>
                  <th class="text-left">Primary contact?</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(contact, index) in contacts"
                  :key="contact.id"
                >
                  <td>{{ contact.full_name }}</td>
                  <td>{{ contact.email }}</td>
                  <td>{{ contact.tel }} {{ contact.mobile}}</td>
                  <td>
                    <input v-if="contact.id === mainContact" checked name="mainContact" type="radio" @change="primaryContact(contact.id)">
                    <input v-else type="radio" name="mainContact" @change="primaryContact(contact.id)">
                  </td>
                  <td>
                    <router-link custom v-slot="{ navigate }" :to="`/contacts/edit/${contact.id}`">
                      <v-btn
                        x-small
                        @click="navigate"
                        @keypress.enter="navigate"
                        role="link">
                        Edit
                      </v-btn>
                    </router-link>
                    &nbsp;
                    <v-btn x-small @click="archive(index)">Archive</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </template>
  
<script>
import axios from '../../axios';

export default {
  name: 'CustomerInfoContacts',
  props: [
    'contacts',
    'mainContact',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    primaryContact(contactId) {
      axios.get(`/contacts/makePrimary/${contactId}.json?token=${this.token}`)
        .catch((error) => {
          console.log(error);
        });
    },
    archive(index) {
      const contact = this.contacts[index];
      axios.get(`/contacts/archive/${contact.id}.json?token=${this.token}`)
        .then(() => {
          this.contacts.splice(index, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  