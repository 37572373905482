<template>
  <div>
    <v-row>
      <v-col class="col4">
        <h2>Previous credit notes for this customer</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col12">
        <v-simple-table
          fixed-header
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Number</th>
                <th class="text-left">Date</th>
                <th class="text-left">Site</th>
                <th class="text-left">Value</th>
                <th class="text-left">Vat</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="note in creditNotes"
                :key="note.id"
              >
                <td>{{ note.number }}</td>
                <td>{{ note.date | tinyDate }}</td>
                <td>{{ note.siteLocation }}</td>
                <td>{{ note.subTotal | priceInPounds | currency }}</td>
                <td>{{ note.vat | priceInPounds | currency }}</td>
                <td>
                  <router-link
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/view-credit-note/${note.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="pb-5">
      <v-col class="text-right">
        <v-btn @click="loadCreditNotes" small>Load More</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'CustomerInfoCreditNotes',
  props: [
    'customerId',
  ],
  data() {
    return {
     creditNotes: [],
     count: 0,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    orderedInvoices() {
      return this.invoices;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  methods: {
    loadCreditNotes() {
      axios.get(`/creditNotes/customerLoadMore/${this.customerId}/${this.count}.json?token=${this.token}`)
        .then((response) => {
          this.creditNotes = this.creditNotes.concat(response.data.creditNotes);
          this.count += 10;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.loadCreditNotes();
  },
};
</script>
