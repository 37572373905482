<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          {{ customer.name }}
          <span class="float-right">
            Customer Spend To Date: {{ customer.spend | priceInPounds | currency }}
          </span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link :to="`/customers/edit/${customer.id}`">
          <v-btn small>Edit</v-btn>
        </router-link>
        &nbsp;
        <router-link :to="`/customers/add-contact/${customer.id}`">
          <v-btn small>Add Contact</v-btn>
        </router-link>
        &nbsp;
        <router-link :to="`/quotations/create/${customer.id}`">
          <v-btn small>New quotation</v-btn>
        </router-link>
        &nbsp;
        <router-link :to="`/invoices/statement/${customer.id}`">
          <v-btn small>Create Statement</v-btn>
        </router-link>
        &nbsp;
        <router-link :to="`/customers/payment/${customer.id}`">
          <v-btn small>Payment</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col4">
        <p>
          <span v-if="customer.address_1">{{ customer.address_1 }}</span>
          <span v-if="customer.address_2"><br />{{ customer.address_2 }}</span>
          <span v-if="customer.address_3"><br />{{ customer.address_3 }}</span>
          <span v-if="customer.address_4"><br />{{ customer.address_4 }}</span>
          <span v-if="customer.address_5"><br />{{ customer.address_5 }}</span>
          <span v-if="customer.postcode"><br />{{ customer.postcode }}</span>
        </p>
      </v-col>
      <v-col class="col4">
        <p>
          <span v-if="customer.tel">Tel: {{ customer.tel }}</span>
          <span v-if="customer.email"><br />Email: {{ customer.email }}</span>
          <span v-if="customer.accounts_email"><br />Accounts email: {{ customer.accounts_email }}</span>
          <span v-if="customer.sage_ref"><br />Sage ref: {{ customer.sage_ref }}</span>
          <span class="text-red" v-else><br />No Sage reference</span>
          <span v-if="customer.vat_registration_no"><br />VAT Reg No: {{ customer.vat_registration_no }}</span><br />
          PO Required?
          <span v-if="customer.po_required">Yes</span>
          <span v-else>No</span>
          <br />
          Current balance:
          <span v-if="creditInfo.balance">{{ creditInfo.balance | currency }}</span>
          <span v-else>Loading</span>
          <span v-if="retentionTotal > 0">
            <br />Current retention total: {{ retentionTotal | priceInPounds | currency }}
          </span>
        </p>
      </v-col>
      <v-col class="col4">
        <span v-if="customer.business_type">
          <span v-if="customer.business_type.business_type.length > 0">
            Business type: {{ customer.business_type.business_type }}
          </span><br />
        </span>
        Credit limit: {{ customer.credit_limit | priceInPounds | currency }}
        <br />
        Payment terms: <span v-if="customer.payment_terms">{{ customer.payment_term.terms }}</span><br />
        Notes: {{ customer.notes }}<br />
        Application for payment?
        <span v-if="customer.i_application">Yes</span>
        <span v-else>No</span><br />
        Blocked?
        <span v-if="customer.blocked">Yes</span>
        <span v-else>No</span><br />
        Cleared?
        <span v-if="customer.cleared">Yes</span>
        <span v-else>No</span>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab">
      <v-tab v-if="customer.contacts.length > 0">Contacts</v-tab>
      <v-tab v-if="customer.quotations.length > 0">Quotations</v-tab>
      <v-tab v-if="customer.invoices.length > 0">Invoices</v-tab>
      <v-tab v-if="customer.creditNotes.length > 0">Credit Notes</v-tab>
      <v-tab v-if="customer.live_jobs.length > 0">Live Jobs</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <app-contacts
          v-if="customer.contacts.length > 0"
          :contacts="customer.contacts"
          :mainContact="customer.main_contact_id" />
      </v-tab-item>
      <v-tab-item>
        <app-quotations
          v-if="customer.quotations.length > 0"
          :quotations="customer.quotations" />
      </v-tab-item>
      <v-tab-item>
        <app-invoices
          v-if="customer.invoices.length > 0"
          :customerId="customer.id" />
      </v-tab-item>
      <v-tab-item>
        <app-credit-notes
          v-if="customer.creditNotes.length > 0"
          :customerId="customer.id" />
      </v-tab-item>
      <v-tab-item>
        <app-live-jobs
          v-if="customer.live_jobs.length > 0"
          :liveJobs="customer.live_jobs" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import axios from '../../axios';
import contacts from '@/components/CustomerInfo/Contacts.vue';
import quotations from '@/components/CustomerInfo/Quotations.vue';
import invoices from '@/components/CustomerInfo/Invoices.vue';
import creditNotes from '@/components/CustomerInfo/CreditNotes.vue';
import livejobs from '@/components/CustomerInfo/LiveJobs.vue';

export default {
  name: 'CustomersView',
  components: {
    appContacts: contacts,
    appQuotations: quotations,
    appInvoices: invoices,
    appCreditNotes: creditNotes,
    appLiveJobs: livejobs,
  },
  data() {
    return {
      customer: {
        contacts: [],
        creditNotes: [],
        invoices: [],
        quotations: [],
        live_jobs: [],
        business_type: {
          business_type: 0,
        },
        address_1: null,
        address_2: null,
        address_3: null,
        address_4: null,
        address_5: null,
        tel: null,
        email: null,
        accounts_email: null,
      },
      creditInfo: {
        creditLimit: null,
        balance: null,
      },
      tab: null,
      retentionTotal: 0,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    getCreditInfo() {
      const customerId = this.$route.params.id;
      axios.get(`/customers/getCreditInfo/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.creditInfo = response.data.creditInfo;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCustomer() {
      const customerId = this.$route.params.id;
      axios.get(`/customers/getSingle/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.customer = response.data.customer;
          this.retentionTotal = response.data.retentionTotal;
          this.getCreditInfo();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCustomer();
  },
};
</script>
