<template>
  <div>
    <v-row>
      <v-col class="col4">
        <h2>Previous jobs for this customer</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col12">
        <v-simple-table
          fixed-header
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Job No</th>
                <th class="text-left">
                  <span class="link" @click="reorderDate">Date</span>
                </th>
                <th class="text-left">Site</th>
                <th class="text-left">Value</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="job in orderedJobs"
                :key="job.id"
              >
                <td>{{ job.jobNumber }}</td>
                <td>{{ job.created | tinyDate }}</td>
                <td>{{ job.site_location }}</td>
                <td>{{ job.total_value | priceInPounds | currency }}</td>
                <td>
                  <router-link custom v-slot="{ navigate }" :to="`/live-jobs/view/${job.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CustomerInfoQuotes',
  props: [
    'liveJobs',
  ],
  computed: {
    orderedJobs() {
      return this.liveJobs;
    },
  },
  methods: {
    reorderDate() {
      const reOrdered = this.orderedJobs.reverse();
      this.orderedJobs = reOrdered;
    },
  },
};
</script>
